import React, { useState, useEffect } from 'react';
import { ArrowRight, Sparkles, Headset, Globe, LucideTwitter, SendHorizontal, LineChart, FileText, Coins } from 'lucide-react';
import { Link } from 'react-router-dom';
import { Card, CardContent } from '../components/ui/card';
import Footer from '../components/footer';

import '../index.css'
// Flipping Word Component
const FlippingWord = () => {
  const words = ['Innovation', 'DeFi', 'Web3', 'the Future'];
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isFlipping, setIsFlipping] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setIsFlipping(true);
      setTimeout(() => {
        setCurrentIndex((prev) => (prev + 1) % words.length);
        setIsFlipping(false);
      }, 500);
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  return (
    <span className="inline-block min-w-[280px] text-left">
      <span
        className={`inline-block text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-blue-400 transition-transform duration-500 ${isFlipping ? 'transform -scale-y-100 opacity-0' : 'transform scale-y-100 opacity-100'
          }`}
      >
        {words[currentIndex]}
      </span>
    </span>
  );
};

// Partner Marquee Component
const PartnerMarquee = ({ partners }) => {
  const duplicatedPartners = [...partners, ...partners];

  return (
    <div className="w-full overflow-hidden bg-gray-800/50 backdrop-blur-sm border-y border-gray-700/50 py-8">
      <div className="animate-marquee flex">
        {duplicatedPartners.map((partner, index) => (
          <div
            key={index}
            className="flex items-center mx-12 shrink-0 group"
          >
            <div className="relative">
              <div className="absolute -inset-1 bg-gradient-to-r from-purple-600 to-blue-600 rounded-lg blur opacity-0 group-hover:opacity-25 transition duration-500" />
              <img
                src={partner.logo || '/api/placeholder/48/48'}
                alt={`${partner.name} logo`}
                className="relative w-12 h-12 rounded-lg mr-3 transition-transform duration-300 group-hover:scale-105"
              />
            </div>
            <span className="text-gray-300 font-medium whitespace-nowrap group-hover:text-white transition-colors">
              {partner.name}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

// Project Card Component
const ProjectCard = ({ project }) => {
  if (!project) {
    return (
      <Card className="bg-gray-800/50 backdrop-blur-sm border border-gray-700/50 rounded-xl h-[600px] flex items-center justify-center group hover:shadow-2xl hover:shadow-purple-500/10 transition-all duration-500">
        <CardContent className="text-center w-full p-8">
          <div className="relative">
            <div className="absolute -inset-1 bg-gradient-to-r from-purple-600 to-blue-600 rounded-lg blur opacity-25 group-hover:opacity-100 transition duration-1000 group-hover:duration-200" />
            <div className="relative">
              <h3 className="text-2xl font-semibold text-gray-400 mb-4">Coming Soon...</h3>
              <p className="text-gray-500">New projects launching shortly</p>
            </div>
          </div>
        </CardContent>
      </Card>
    );
  }

  const { shortName, longName, description, banner, logo, links, comingSoon } = project;

  return (
    <Card className="bg-gray-800/50 backdrop-blur-sm border border-gray-700/50 rounded-xl transition-all duration-300 group hover:shadow-2xl hover:shadow-purple-500/10 overflow-hidden h-[600px] flex flex-col">
      <CardContent className="p-0 h-full flex flex-col">
        <div className="p-8 space-y-6 flex-1 flex flex-col min-h-0">
          <div className="flex items-start gap-6 flex-shrink-0">
            <div className="relative shrink-0">
              <div className="absolute -inset-1 bg-gradient-to-r from-purple-600 to-blue-600 rounded-lg blur opacity-25 group-hover:opacity-100 transition duration-1000 group-hover:duration-200" />
              <div className="relative">
                {logo && (
                  <img src={logo} alt={`${shortName} logo`} className="w-14 h-14 rounded-xl" />
                )}
              </div>
            </div>
            <div className="min-w-0 pt-2">
              <h3 className="text-2xl font-bold text-white mb-1 truncate">{shortName}</h3>
              <p className="text-gray-400 text-sm truncate">{longName}</p>
            </div>
          </div>

          <div className="relative h-56 overflow-hidden rounded-xl flex-shrink-0">
            <div className="absolute inset-0 bg-gradient-to-br from-purple-600/20 to-blue-600/20 group-hover:opacity-0 transition-opacity z-10" />
            {comingSoon && (
              <div className="absolute inset-0 flex items-center justify-center bg-black/50 z-20">
                <div className="text-4xl font-bold text-white/90 tracking-wider rotate-[-12deg] transform transition-transform duration-300 group-hover:rotate-0 group-hover:scale-110"
                style={{ 
                  color: 'rgba(255, 255, 255, 0.7)',
                  textShadow: '2px 2px 4px rgba(0, 0, 0, 0.3)'
                }}>
                
                  Coming Soon!
                </div>
              </div>
            )}
            <img
              src={banner || '/api/placeholder/400/320'}
              alt={`${shortName} banner`}
              className="w-full h-full object-cover transition-all duration-700 group-hover:scale-105"
            />
          </div>

          <div className="flex-1 min-h-0 overflow-y-auto pr-2 custom-scrollbar">
            <p className="text-gray-300 leading-relaxed">{description}</p>
          </div>

          {links && links.length > 0 && (
            <div className="flex gap-4 flex-shrink-0 mt-4">
              {links.map((link, index) => {
                const Icon = {
                  twitter: LucideTwitter,
                  website: Globe,
                  headset: Headset
                }[link.type] || Globe;

                return (
                  <a
                    key={index}
                    href={link.url}
                    className="p-3 rounded-xl bg-gray-700/50 hover:bg-gray-700 transition-all duration-300 hover:shadow-lg hover:shadow-purple-500/10"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Icon className="w-5 h-5 text-gray-300" />
                  </a>
                );
              })}
            </div>
          )}
        </div>
      </CardContent>
    </Card>
  );
};

// Main HomePage Component
const HomePage = () => {
    const scrollToProjects = () => {
        document.getElementById('projects-section').scrollIntoView({ 
          behavior: 'smooth',
          block: 'start'
        });
      };
    
  const [projects, setProjects] = useState([]);
  const [partners, setPartners] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [projectsRes, partnersRes] = await Promise.all([
          fetch('/data/projects.json'),
          fetch('/data/partners.json')
        ]);
        
        if (!projectsRes.ok || !partnersRes.ok) {
          throw new Error('Failed to fetch data');
        }

        const [projectsData, partnersData] = await Promise.all([
          projectsRes.json(),
          partnersRes.json()
        ]);

        setProjects(projectsData.projects || []);
        setPartners(partnersData.partners || []);
      } catch (error) {
        console.error('Error:', error);
        setProjects([]);
        setPartners([]);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="min-h-screen bg-gray-900 relative overflow-hidden">
      {/* Grid Pattern Background */}
      <div className="absolute inset-0 opacity-10">
        <div className="absolute inset-0"
          style={{
            backgroundImage: `linear-gradient(90deg, rgb(75 85 99 / 1) 1px, transparent 1px),
                             linear-gradient(rgb(75 85 99 / 1) 1px, transparent 1px)`,
            backgroundSize: '64px 64px'
          }}>
        </div>
      </div>

      {/* Gradient Overlay */}
      <div className="absolute inset-0 bg-gradient-to-br from-purple-900/30 via-transparent to-blue-900/30" />

      {/* Main Content */}
      <nav className="border-b border-gray-800/50 bg-gray-900/80 backdrop-blur-sm sticky top-0 z-10">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex items-center justify-between h-16">
            <div className="flex items-center">
              <Link to="/" className="text-2xl font-bold text-white flex items-center gap-2 hover:opacity-80 transition-opacity">
                <Sparkles className="h-6 w-6" />
                solhub.ai
              </Link>
            </div>
            <div className="hidden md:block">
              <div className="flex items-center space-x-8">
                <button onClick={scrollToProjects} className="text-gray-300 hover:text-white transition-colors">
                  Projects
                </button>
                {/* <Link to="/token" className="flex items-center text-gray-300 hover:text-white transition-colors gap-2">
                  <Coins className="h-4 w-4" />
                  $SET Token
                </Link> */}
                {/* <Link to="/whitepaper" className="flex items-center text-gray-300 hover:text-white transition-colors gap-2">
                  <FileText className="h-4 w-4" />
                  Whitepaper
                </Link> */}
                {/* <Link to="/about" className="text-gray-300 hover:text-white transition-colors">
                  About
                </Link> */}
                <Link to="/contact" className="text-gray-300 hover:text-white transition-colors">
                  Contact
                </Link>
              </div>
            </div>
          </div>
        </div>
      </nav>


        {/* Hero Section */}
        <div className="text-center">
        <div className="flex justify-center items-center mt-8 mb-8">
          <img 
            src="/images/logos/ferret_gradient_1200x1200.png" 
            alt="Ferret Logo" 
            className="w-48 h-48 opacity-100"
          />
        </div>
    <h1 className="text-5xl md:text-7xl font-bold text-white mb-6 tracking-tight">
      Empowering {' '}
      <FlippingWord />
    </h1>
    <p className="text-xl text-gray-400 max-w-2xl mx-auto mb-12">
      Accelerating the future of Web3 through innovative Solana social projects and groundbreaking technologies.
    </p>
    <button 
      onClick={scrollToProjects}
      className="relative z-30 inline-flex items-center gap-2 bg-gradient-to-r from-purple-500 to-blue-500 text-white px-8 py-3 rounded-xl font-medium hover:opacity-90 transition-all duration-300 hover:shadow-lg hover:shadow-purple-500/50 cursor-pointer"  >
      View Projects
      <ArrowRight className="h-5 w-5" />
    </button>
  </div>

        <div id="projects-section" className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
  {!projects || projects.length === 0 ? (
    <div className="space-y-8">
      <h2 className="text-3xl font-bold text-white mb-8">Featured Project</h2>
      <div className="max-w-3xl mx-auto">
        <ProjectCard project={null} />
      </div>
    </div>
  ) : projects.length === 1 ? (
    <div className="space-y-8">
      <h2 className="text-3xl font-bold text-white mb-8">Featured Project</h2>
      <div className="max-w-3xl mx-auto">
        <ProjectCard project={projects[0]} />
      </div>
    </div>
  ) : (
    <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
      <div className="lg:col-span-2">
        <h2 className="text-3xl font-bold text-white mb-8">Featured Project</h2>
        <ProjectCard project={projects[0]} />
      </div>
      <div>
        <h2 className="text-3xl font-bold text-white mb-8">More Projects</h2>
        <div className="h-[600px] pr-4 overflow-auto">
          <div className="space-y-6">
            {projects.slice(1).map((project, index) => (
              <ProjectCard key={index} project={project} />
            ))}
          </div>
        </div>
      </div>
    </div>
  )}
</div>

  {/* Partners Section */}
  <div className="py-16">
  <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 mb-8" />
</div>
  {/* <div className="py-16">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 mb-8">
            <h2 className="text-3xl font-bold text-white text-center">Our Partners</h2>
          </div>
          {partners && partners.length > 0 && <PartnerMarquee partners={partners} />}
        </div> */}

        {/* Footer */}
        <Footer />
      </div>
  );
};

// Add these styles to your index.css file instead of inline
export const styles = {
  marquee: `
    @keyframes marquee {
      0% {
        transform: translateX(0);
      }
      100% {
        transform: translateX(-50%);
      }
    }
    
    .animate-marquee {
      animation: marquee 30s linear infinite;
    }
  `
};

export default HomePage;