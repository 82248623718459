import React from 'react';
import { Sparkles, Twitter, SendHorizontal, LineChart } from 'lucide-react';

const Footer = () => {
  return React.createElement(
    'footer',
    {
      className: 'bg-gray-800/50 backdrop-blur-sm border-t border-gray-700/50'
    },
    React.createElement(
      'div',
      {
        className: 'max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8'
      },
      React.createElement(
        'div',
        {
          className: 'grid grid-cols-3 items-center'
        },
        React.createElement(
          'div',
          {
            className: 'flex items-center'
          },
          React.createElement(Sparkles, {
            className: 'h-6 w-6 text-white mr-2'
          }),
          React.createElement(
            'span',
            {
              className: 'text-xl font-bold text-white'
            },
            'solhub.ai'
          )
        ),
        React.createElement(
          'div',
          {
            className: 'flex items-center justify-center gap-6'
          },
          React.createElement(
            'a',
            {
              href: 'https://twitter.com/solhubai',
              className: 'p-3 rounded-xl bg-gray-700/50 hover:bg-gray-700 transition-all duration-300 hover:shadow-lg hover:shadow-purple-500/10 group',
              target: '_blank',
              rel: 'noopener noreferrer'
            },
            React.createElement(Twitter, {
              className: 'w-5 h-5 text-gray-300 group-hover:text-white transition-colors'
            })
          ),
          React.createElement(
            'a',
            {
              href: 'https://dexscreener.com/solhub',
              className: 'p-3 rounded-xl bg-gray-700/50 hover:bg-gray-700 transition-all duration-300 hover:shadow-lg hover:shadow-purple-500/10 group',
              target: '_blank',
              rel: 'noopener noreferrer'
            },
            React.createElement(LineChart, {
              className: 'w-5 h-5 text-gray-300 group-hover:text-white transition-colors'
            })
          )
        ),
        React.createElement(
          'div',
          {
            className: 'text-gray-400 text-sm hover:text-gray-300 transition-colors text-right'
          },
          '© 2024 solhub.ai. All rights reserved.'
        )
      )
    )
  );
};

export default Footer;